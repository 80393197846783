import { take, fork } from "redux-saga/effects";
function* errorWorker(action) {
  yield console.log(action);
}

function* errorWatcher() {
  while (true) {
    const action = yield take("ERROR", errorWorker);
    yield fork(errorWorker, action);
  }
}

export default errorWatcher;
