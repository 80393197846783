import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";
import subRouter from "./subRouter";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import customHistory from "./history";
const childRoutes = (Layout, routes) => {
  const r = routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map((route) => subRouter({ route, Layout }))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

  return r;
};

const Routes = () => {
  const AuthRoutes = childRoutes(AuthLayout, authRoutes);
  const DashRoutes = childRoutes(DashboardLayout, dashboardRoutes);

  return (
    <Router history={customHistory}>
      <Switch>
        {[...AuthRoutes, ...DashRoutes]}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
