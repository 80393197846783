import React from "react";

import async from "../components/Async";

import {
  BookOpen,
  Briefcase,
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  Monitor,
  PieChart,
  Eye,
  User,
  Users,
} from "react-feather";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const Invoice = async(() => import("../pages/pages/Invoice"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));

// Lead
const LeadTable = async(() => import("../pages/leads"));
const API = async(() => import("../pages/API"));
const APILeads = async(() => import("../pages/APILeads"));

const dashboardsRoutes = {
  id: "Leads",
  header: "Menu",
  icon: <Eye />,
  children: [
    {
      path: "/",
      name: "Todos",
      type: "private",
      exact: true,
      Component: APILeads,
    },

    {
      path: "/api",
      name: "Mi organizacion",
      type: "private",
      exact: true,
      Component: API,
    },
  ],
};

//d
const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/pages/settings",
      name: "Settings",
      Component: Settings,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      Component: Pricing,
    },
    {
      path: "/pages/blank",
      name: "Blank Page",
      Component: Blank,
    },
  ],
};

const profileRoutes = {
  id: "Profile",
  path: "/profile",
  icon: <User />,
  Component: Profile,
  children: null,
};

const projectsRoutes = {
  id: "Projects",
  path: "/projects",
  icon: <Briefcase />,
  badge: "8",
  Component: Projects,
  children: null,
};

const invoiceRoutes = {
  id: "Invoice",
  path: "/invoice",
  icon: <CreditCard />,
  Component: Invoice,
  children: null,
};

const tasksRoutes = {
  id: "Tasks",
  path: "/tasks",
  icon: <CheckSquare />,
  badge: "17",
  Component: Tasks,
  children: null,
};

const calendarRoutes = {
  id: "Calendar",
  path: "/calendar",
  icon: <CalendarIcon />,
  Component: Calendar,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      type: "public",
      exact: true,
      Component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      type: "public",
      exact: true,

      Component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      type: "public",
      exact: true,

      Component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      type: "public",
      exact: false,

      Component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      type: "public",
      exact: false,

      Component: Page500,
    },
  ],
};

const documentationRoutes = {
  id: "Getting Started",
  path: "/documentation",
  icon: <BookOpen />,
  Component: Docs,
  children: null,
};

const changelogRoutes = {
  id: "Changelog",
  path: "/changelog",
  badge: "v1.0.5",
  icon: <List />,
  Component: Changelog,
  children: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
  id: "Private",
  path: "/private",
  Component: Blank,
  children: null,
};

export const dashboard = [
  dashboardsRoutes,
  // pagesRoutes,
  // profileRoutes,
  // projectsRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // chartRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // presentationRoutes,
  // documentationRoutes,
  // changelogRoutes,
  // privateRoutes,
];

export const auth = [authRoutes];

export default [dashboardsRoutes];
