import { ERROR, WARNING, SUCCESS, CLEAR } from "../actions/dialog";

const initialState = {
  visible: false,
  type: "",
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      return {
        visible: true,
        type: ERROR,
        message: action.message,
      };
    case WARNING:
      return {
        visible: true,
        type: WARNING,
        message: action.message,
      };
    case SUCCESS:
      return {
        visible: true,
        type: SUCCESS,
        message: action.message,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}
