/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      tenant
      subTenant
      organizationName
      owner
      leads {
        items {
          id
          leadID
          tenant
          subTenant
          step
          type
          budget
          birthdate
          leadStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMember {
        items {
          id
          owner
          teamMemberID
          firstName
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      savedLeads
      removedLeads
      test
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelorganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenant
        subTenant
        organizationName
        owner
        leads {
          nextToken
        }
        teamMember {
          nextToken
        }
        savedLeads
        removedLeads
        test
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      owner
      teamMemberID
      firstName
      lastName
      phone
      email
      organization {
        id
        tenant
        subTenant
        organizationName
        owner
        leads {
          nextToken
        }
        teamMember {
          nextToken
        }
        savedLeads
        removedLeads
        test
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        teamMemberID
        firstName
        lastName
        phone
        email
        organization {
          id
          tenant
          subTenant
          organizationName
          owner
          savedLeads
          removedLeads
          test
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      leadID
      tenant
      subTenant
      step
      type
      budget
      address {
        address1
        address2
        address3
      }
      personal {
        fullName
        email
        phone
      }
      birthdate
      livingSituation {
        type
        monthlyPayment
      }
      employment {
        employmentType
        companyName
        roleName
        isWorkingStudent
        otherDescription
      }
      income {
        monthlyIncome
        income3Months
      }
      leadStatus
      createdAt
      updatedAt
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        leadID
        tenant
        subTenant
        step
        type
        budget
        address {
          address1
          address2
          address3
        }
        personal {
          fullName
          email
          phone
        }
        birthdate
        livingSituation {
          type
          monthlyPayment
        }
        employment {
          employmentType
          companyName
          roleName
          isWorkingStudent
          otherDescription
        }
        income {
          monthlyIncome
          income3Months
        }
        leadStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
