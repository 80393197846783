export const ERROR = "ERROR";
export const WARNING = "WARNING";
export const SUCCESS = "SUCCESS";
export const CLEAR = "CLEAR";

export const setError = (message) => {
  return { type: ERROR, message };
};

export const setWarning = (message) => {
  return { type: WARNING, message };
};

export const setSuccess = (message) => {
  return { type: SUCCESS, message };
};

export const clearDialog = () => {
  return { type: CLEAR };
};
