import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

// Simple queryfetchOrganization

export function listOrganizations() {
  return API.graphql(graphqlOperation(queries.listOrganizations));
}

export function createOrganization(org) {
  console.log(org);
  return API.graphql(
    graphqlOperation(mutations.createOrganization, { input: org })
  );
}

export function updateOrganization(org) {
  return API.graphql(
    graphqlOperation(mutations.updateOrganization, { input: org })
  );
}

export function subscribeOrganization() {
  return API.graphql(graphqlOperation(subscriptions.onUpdateOrganization));
}

export function fetchOrganization() {
  return API.graphql(graphqlOperation(queries.listOrganizations));
}

export function saveOrganizationLead(leadId, org) {
  let savedLeads = org.savedLeads;
  if (savedLeads == null) {
    savedLeads = [leadId];
  } else if (!savedLeads.some((saved) => saved === leadId)) {
    savedLeads.push(leadId);
  }

  const l = { ...org, savedLeads };

  return updateOrganization(l);
}
