import { combineReducers } from "redux";

import themeReducer from "./themeReducers";
import dialog from "./dialog";
import auth from "./auth";
import organization from "./organization";
import leads from "./leads";

export default combineReducers({
  themeReducer,
  dialog,
  auth,
  organization,
  leads,
});
