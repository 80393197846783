/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "autofica-dashboard-20200211015959-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://autofica-dashboard-20200211015959-hostingbucket-dev.s3-website.us-east-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-2:5ffc37a1-c1cf-4376-ac50-1fc2effe1747",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_SjBGIj2G1",
    "aws_user_pools_web_client_id": "1aufeagrq20bopsg3cl13thabn",
    "oauth": {
        "domain": "autoficadashboard11b8105a-11b8105a-dev.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://shvh66avo1.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jnltq7wzx5cqhd3nherdorebw4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
