import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import createSagaMiddleware from "redux-saga";
import sagas from "../sagas";
let devtools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : null;

const sagaMiddleware = createSagaMiddleware();

let store;
if (devtools) {
  store = createStore(
    rootReducer,
    compose(applyMiddleware(...[sagaMiddleware]), devtools)
  );
} else {
  store = createStore(
    rootReducer,
    compose(applyMiddleware(...[sagaMiddleware]))
  );
}

sagaMiddleware.run(sagas);

export default store;
