import {
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  all,
  race,
  select,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { setError } from "../actions/dialog";

import {
  CREATE_ORGANIZATION,
  FETCH_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SAVE_ORGANIZATION_LEAD,
  setOrganization,
} from "../actions/organization";

import { SIGN_OUT, POST_SIGN_IN } from "../actions/auth";

import {
  createOrganization,
  fetchOrganization,
  updateOrganization,
  subscribeOrganization,
  saveOrganizationLead,
} from "../../api/organization";
import { selectOrganization } from "../selectors/organization";
import { selectAuthorizationLevel } from "../selectors/auth";
import { AUTH_LEVELS } from "../const/auth";

const EMPTY_ORGANIZATION = [];

function getOrganizationByAuth(authLevel, organizationOrig) {
  console.log(organizationOrig);
  const organization = { ...organizationOrig };
  delete organization.teamMember;
  delete organization.createdAt;
  delete organization.updatedAt;

  delete organization.leads;
  if (authLevel === AUTH_LEVELS.ADMIN) {
    return organization;
  }

  delete organization.tenant;
  delete organization.subTenant;

  return organization;
}
function* saveOrganizationLeadWorker(lead) {
  try {
    const state = yield select();

    const authLevel = yield select(selectAuthorizationLevel);
    const strippedOrg = yield call(
      getOrganizationByAuth,
      authLevel,
      state.organization.organization[0]
    );

    yield call(saveOrganizationLead, lead, strippedOrg);
  } catch (err) {
    console.log(err);
    yield put(setError(err.name));
  }
}

function* createOrganizationWorker(organization) {
  try {
    yield createOrganization(organization);
  } catch (err) {
    console.log(err);
    yield put(setError(err.name));
  }
}

function* fetchOrganizationWorker() {
  try {
    const org = yield fetchOrganization();
    yield put(setOrganization(org.data.listOrganizations.items));
  } catch (err) {
    console.log(err);
    yield put(setError(err.name));
  }
}

function* updateOrganizationWorker(organization) {
  try {
    const authLevel = yield select(selectAuthorizationLevel);
    const strippedOrg = yield call(
      getOrganizationByAuth,
      authLevel,
      organization
    );
    console.log("strippedOrg", strippedOrg);
    yield updateOrganization(strippedOrg);
    // if (authLevel != AUTH_LEVELS.ADMIN) {
    yield call(fetchOrganizationWorker);
    // }
  } catch (err) {
    console.log(err);
    yield put(setError(err.errors[0].message));
  }
}

function* signOutWorker() {
  try {
    const org = yield fetchOrganization();
    yield put(setOrganization(EMPTY_ORGANIZATION));
  } catch (err) {
    yield put(setError(err.name));
  }
}

function* createOrganizationWatcher() {
  while (true) {
    const action = yield take(CREATE_ORGANIZATION);
    yield call(createOrganizationWorker, action.organization);
  }
}

function* fetchOrganizationWatcher() {
  while (true) {
    const action = yield take(FETCH_ORGANIZATION);
    yield call(fetchOrganizationWorker, action.organization);
  }
}

function* updateOrganizationWatcher() {
  while (true) {
    const action = yield take(UPDATE_ORGANIZATION);
    yield call(updateOrganizationWorker, action.organization);
  }
}

function subscription() {
  return eventChannel(function (emmiter) {
    let sub = subscribeOrganization().subscribe({
      next: (data) => {
        emmiter(data.value.data.onUpdateOrganization);
      },
      error: (error) => {
        console.log(error);

        emmiter(new Error(error));
      },
    });

    return function () {
      sub.unsubscribe();
    };
  });
}

function* subscribeOrganizationWatcher() {
  const channel = yield call(subscription);

  const authLevel = yield select(selectAuthorizationLevel);

  if (authLevel != AUTH_LEVELS.ADMIN) {
    return;
  }
  while (true) {
    try {
      const data = yield take(channel);
      const organizations = yield select(selectOrganization);
      const result = organizations.map(function (org) {
        if (org.id !== data.id) return org;
        return data;
      });
      console.log(result);
      yield put(setOrganization(result));
    } catch (err) {
      console.log(err);
      yield put(setError("Subscription error"));
    }
  }
}

function* signOutWatcher() {
  while (true) {
    const action = yield take(SIGN_OUT);
    yield call(signOutWorker, action.organization);
  }
}

function* postSignInWatcher() {
  while (true) {
    const action = yield take(POST_SIGN_IN);
    yield call(subscribeOrganizationWatcher);
  }
}

function* saveOrganizationLeadWatcher() {
  while (true) {
    const action = yield take(SAVE_ORGANIZATION_LEAD);
    yield call(saveOrganizationLeadWorker, action.lead);
  }
}

export default function* organizationSagas() {
  yield all([
    createOrganizationWatcher(),
    fetchOrganizationWatcher(),
    updateOrganizationWatcher(),
    signOutWatcher(),
    saveOrganizationLeadWatcher(),
    // postSignInWatcher(),
  ]);
}
