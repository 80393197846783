import { all, call, put, take } from "redux-saga/effects";
import { fetchLeads } from "../../api/leads";
import { setError } from "../actions/dialog";
import { FETCH_LEADS, setLeads } from "../actions/leads";

function* fetchLeadsWorker() {
  try {
    const ob = yield call(fetchLeads);
    const leads = ob.data.listLeads.items;
    console.log("leads", leads);
    yield put(setLeads(leads));
  } catch (err) {
    console.log(err);
    yield put(setError(err.name));
  }
}

function* fetchLeadsWatcher() {
  while (true) {
    const action = yield take(FETCH_LEADS);
    yield call(fetchLeadsWorker, action.leads);
  }
}

export default function* leadsSagas() {
  yield all([fetchLeadsWatcher()]);
}
