export const CREATE_LEADS = "CREATE_LEADS";
export const FETCH_LEADS = "FETCH_LEADS";
export const SET_LEADS = "SET_LEADS";
export const UPDATE_LEADS = "UPDATE_LEADS";

export const createLeads = (leads) => {
  return { type: CREATE_LEADS, leads };
};

export const updateLeads = (leads) => {
  return { type: UPDATE_LEADS, leads };
};

export const fetchLeads = () => {
  return { type: FETCH_LEADS };
};

export const setLeads = (leads) => {
  return { type: SET_LEADS, leads };
};
