import { AUTH_LEVELS } from "../const/auth";

export function isAdminPayload(cognito) {
  const payload = cognito.signInUserSession?.idToken?.payload;

  if (payload == null) {
    return AUTH_LEVELS.VISITOR;
  }
  const groups = payload["cognito:groups"];

  if (!Array.isArray(groups)) {
    return AUTH_LEVELS.VISITOR;
  }

  if (groups.includes("Admins")) {
    return AUTH_LEVELS.ADMIN;
  }

  if (
    groups.some(function (item) {
      return typeof item === "string" && item.includes("MAIN");
    })
  ) {
    return AUTH_LEVELS.MAIN_TENANT;
  }

  if (
    groups.some(function (item) {
      return typeof item === "string" && item.includes("EMP");
    })
  ) {
    return AUTH_LEVELS.SUB_TENANT;
  }

  return AUTH_LEVELS.VISITOR;
}

export function selectAuthorizationLevel(state) {
  return state.auth.level;
}
