import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Bg = styled.div`
  width: 100vw;
  height: 100vh;
  background-size: cover;
  filter: blur(10px);
  position: absolute;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("/static/img/unsplash/road.jpg");
`;

function Auth({ children }) {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Bg />
      {children}
    </Root>
  );
}

export default Auth;
