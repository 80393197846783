import Amplify from "aws-amplify";
export function signIn(email, password) {
  return Amplify.Auth.signIn(email, password);
}

export function signOut() {
  return Amplify.Auth.signOut();
}

export function signUp(email, password) {
  return Amplify.Auth.signUp({
    username: email,
    password,
    attributes: {
      email, // optional
    },
  });
}

export function checkLogin() {
  return Amplify.Auth.currentAuthenticatedUser();
}

export function forgotPassword(username) {
  return Amplify.Auth.forgotPassword(username);
}

export function forgotPasswordSubmit(username, code, new_password) {
  return Amplify.Auth.forgotPasswordSubmit(username, code, new_password);
}

export function completeNewPassword(user, newPassword, args) {
  return Amplify.Auth.completeNewPassword(user, newPassword, args);
}
