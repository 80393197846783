import React from "react";
import { PUBLIC, PRIVATE } from "./routeTypes";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function PublicRoute({ route, Layout }) {
  const { Component, ...rest } = route;
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  console.log("PublicRoute", rest);

  return (
    <Route
      {...rest}
      render={(p) => {
        console.log("render", route);

        if (route.redirect) return <Redirect to={route.redirect} />;
        else if (loggedIn) {
          return <Redirect push to={"/"} />;
        } else
          return (
            <Layout>
              <Component {...route} />
            </Layout>
          );
      }}
    />
  );
}

function PrivateRoute({ route, Layout }) {
  const { Component, ...rest } = route;
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  console.log("privateroute", rest);

  return (
    <Route
      {...rest}
      render={(p) => {
        console.log("render", route);
        if (route.redirect) return <Redirect to={route.redirect} />;
        else if (loggedIn)
          return (
            <Layout>
              <Component {...route} />
            </Layout>
          );
        else return <Redirect push to={"/auth/sign-in"} />;
      }}
    />
  );
}

function DualRoute({ route, Layout }) {
  const { Component, ...rest } = route;
  console.log("dualroute");
  const loggedIn = useSelector((state) => state.auth.loggedIn);
  return (
    <Route
      {...rest}
      render={(p) => {
        if (route.redirect) return <Redirect to={route.redirect} />;
        else if (loggedIn)
          return (
            <Layout>
              <route.private {...route} />
            </Layout>
          );
        else
          return (
            <Layout>
              <route.public {...route} />
            </Layout>
          );
      }}
    />
  );
}

function Subrouter({ route, Layout }) {
  switch (route.type) {
    case PUBLIC:
      return PublicRoute({ route, Layout });
    case PRIVATE:
      return PrivateRoute({ route, Layout });
    default:
      return DualRoute({ route, Layout });
  }
}

export default Subrouter;
