import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Dialog = ({ dialog, handleClose }) => {
  return (
    <Snackbar
      open={dialog.visible}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={dialog.type.toLowerCase()}>
        {dialog.message}
      </Alert>
    </Snackbar>
  );
};
export default Dialog;
