import {
  LOGIN_CHECKED,
  LOGGED_IN,
  SET_AUTHORIZATION_LEVEL,
  RESET_REQUIRED,
} from "../actions/auth";

import { AUTH_LEVELS } from "../const/auth";
import { SIGN_OUT, SET_USER_DATA } from "../actions/auth";

const initialState = {
  loginChecked: false,
  loggedIn: false,
  userData: null,
  level: AUTH_LEVELS.VISITOR,

  resetRequired: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_CHECKED:
      return { ...state, loginChecked: action.loginChecked };
    case LOGGED_IN:
      return { ...state, loggedIn: action.loggedIn };
    case SET_AUTHORIZATION_LEVEL:
      return { ...state, level: action.level };
    case RESET_REQUIRED:
      return { ...state, resetRequired: action.value };
    case SIGN_OUT:
      return initialState;
    case SET_USER_DATA:
      return { ...state, userData: action.value };
    default:
      return state;
  }
}
