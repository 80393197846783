const FACEBOOK = "facebook";
const GOOGLE = "google";
const EMAIL = "email";
const UNKNOWN_SIGNIN_PROVIDER = "Unknown Sign in provider";

const AUTH_LEVELS = {
  ADMIN: 0,
  MAIN_TENANT: 1,
  SUB_TENANT: 2,
  VISITOR: 4,
};

export { FACEBOOK, GOOGLE, EMAIL, UNKNOWN_SIGNIN_PROVIDER, AUTH_LEVELS };
