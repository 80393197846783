import React from "react";
function AuthCheck(props) {
  const { loginChecked, checkLogin } = props;

  React.useEffect(function () {
    if (!loginChecked) {
      checkLogin();
    }
  });

  console.log("loginChecked", loginChecked);

  console.log(props.children);

  return !loginChecked ? null : props.children;
}

export default AuthCheck;
