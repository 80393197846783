import * as types from "../actions/organization";
import { SIGN_OUT } from "../actions/auth";

const defaultState = {
  organization: [],
};

export default function reducer(state = defaultState, action) {
  console.log(action);
  switch (action.type) {
    case types.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };
    case SIGN_OUT:
      return defaultState;

    default:
      return state;
  }
}
