import { connect } from "react-redux";
import { clearDialog } from "../../redux/actions/dialog";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return { dialog: state.dialog };
}

function mapDispatchToProps(dispatch) {
  return { handleClose: () => dispatch(clearDialog()) };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
