export const SIGN_IN = "SIGN_IN";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const LOGIN_CHECKED = "LOGIN_CHECKED";
export const WORKER_CHECK_LOGIN = "WORKER_CHECK_LOGIN";
export const LOGGED_IN = "LOGGED_IN";
export const RESET_REQUIRED = "RESET_REQUIRED";
export const SET_USER_DATA = "SET_USER_DATA";
export const WORKER_RESET_PASSWORD = "WORKER_RESET_PASSWORD";

export const SET_AUTHORIZATION_LEVEL = "SET_AUTHORIZATION_LEVEL";

export const signIn = (email, password, provider = "email") => {
  return { type: SIGN_IN, provider, email, password };
};

export const signOut = () => {
  return { type: SIGN_OUT };
};

export const setLoginChecked = (loginChecked) => {
  return { type: LOGIN_CHECKED, loginChecked };
};

export const setLoggedIn = (loggedIn) => {
  return { type: LOGGED_IN, loggedIn };
};
export const checkLoginWorker = () => {
  return { type: WORKER_CHECK_LOGIN };
};

export const setAuthorizationLevel = (level) => {
  return { type: SET_AUTHORIZATION_LEVEL, level };
};

export const postSignIn = () => ({
  type: POST_SIGN_IN,
});

export const setResetRequired = (value) => {
  return { type: RESET_REQUIRED, value };
};
export const setUserData = (value) => {
  return { type: SET_USER_DATA, value };
};
export const resetPassword = (password, name, lastName) => {
  return { type: WORKER_RESET_PASSWORD, password, name, lastName };
};
