import { connect } from "react-redux";
import { checkLoginWorker } from "../../redux/actions/auth";
import View from "./view";

function mapStateToProps(state, ownProps) {
  return { loginChecked: state.auth.loginChecked };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    checkLogin: () => {
      return dispatch(checkLoginWorker());
    },
  };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
