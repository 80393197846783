import React from "react";
import { connect } from "react-redux";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { I18nextProvider } from "react-i18next";
import Dialog from "./components/Dialog";
import Authcheck from "./components/Authcheck";
import i18next from "i18next";

import maTheme from "./theme";
import Routes from "./routes/Routes";

import awsconfig from "aws-exports";
import Amplify from "aws-amplify";

Amplify.configure(awsconfig);

const resources = {
  en: {
    translation: {
      Menu: "Menu",
      Lead: "Lead",
      All: "All",
      New: "New",
    },
  },
  es: {
    translation: {
      Menu: "Menú",
      Lead: "Lead",
      All: "Todos",
      New: "Nuevos",
    },
  },
};

i18next.init({
  resources,
  lng: "en",
  interpolation: { escapeValue: false }, // React already does escaping
});

function App({ theme }) {
  return (
    <I18nextProvider i18n={i18next}>
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Authcheck>
                <Routes />
                <Dialog />
              </Authcheck>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </I18nextProvider>
  );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
