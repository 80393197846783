/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelorganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      tenant
      subTenant
      organizationName
      owner
      leads {
        items {
          id
          leadID
          tenant
          subTenant
          step
          type
          budget
          birthdate
          leadStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMember {
        items {
          id
          owner
          teamMemberID
          firstName
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      savedLeads
      removedLeads
      test
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelorganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      tenant
      subTenant
      organizationName
      owner
      leads {
        items {
          id
          leadID
          tenant
          subTenant
          step
          type
          budget
          birthdate
          leadStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMember {
        items {
          id
          owner
          teamMemberID
          firstName
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      savedLeads
      removedLeads
      test
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelorganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      tenant
      subTenant
      organizationName
      owner
      leads {
        items {
          id
          leadID
          tenant
          subTenant
          step
          type
          budget
          birthdate
          leadStatus
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMember {
        items {
          id
          owner
          teamMemberID
          firstName
          lastName
          phone
          email
          createdAt
          updatedAt
        }
        nextToken
      }
      savedLeads
      removedLeads
      test
      createdAt
      updatedAt
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      owner
      teamMemberID
      firstName
      lastName
      phone
      email
      organization {
        id
        tenant
        subTenant
        organizationName
        owner
        leads {
          nextToken
        }
        teamMember {
          nextToken
        }
        savedLeads
        removedLeads
        test
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      owner
      teamMemberID
      firstName
      lastName
      phone
      email
      organization {
        id
        tenant
        subTenant
        organizationName
        owner
        leads {
          nextToken
        }
        teamMember {
          nextToken
        }
        savedLeads
        removedLeads
        test
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      owner
      teamMemberID
      firstName
      lastName
      phone
      email
      organization {
        id
        tenant
        subTenant
        organizationName
        owner
        leads {
          nextToken
        }
        teamMember {
          nextToken
        }
        savedLeads
        removedLeads
        test
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLead = /* GraphQL */ `
  mutation CreateLead(
    $input: CreateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    createLead(input: $input, condition: $condition) {
      id
      leadID
      tenant
      subTenant
      step
      type
      budget
      address {
        address1
        address2
        address3
      }
      personal {
        fullName
        email
        phone
      }
      birthdate
      livingSituation {
        type
        monthlyPayment
      }
      employment {
        employmentType
        companyName
        roleName
        isWorkingStudent
        otherDescription
      }
      income {
        monthlyIncome
        income3Months
      }
      leadStatus
      createdAt
      updatedAt
    }
  }
`;
export const updateLead = /* GraphQL */ `
  mutation UpdateLead(
    $input: UpdateLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    updateLead(input: $input, condition: $condition) {
      id
      leadID
      tenant
      subTenant
      step
      type
      budget
      address {
        address1
        address2
        address3
      }
      personal {
        fullName
        email
        phone
      }
      birthdate
      livingSituation {
        type
        monthlyPayment
      }
      employment {
        employmentType
        companyName
        roleName
        isWorkingStudent
        otherDescription
      }
      income {
        monthlyIncome
        income3Months
      }
      leadStatus
      createdAt
      updatedAt
    }
  }
`;
export const deleteLead = /* GraphQL */ `
  mutation DeleteLead(
    $input: DeleteLeadInput!
    $condition: ModelLeadConditionInput
  ) {
    deleteLead(input: $input, condition: $condition) {
      id
      leadID
      tenant
      subTenant
      step
      type
      budget
      address {
        address1
        address2
        address3
      }
      personal {
        fullName
        email
        phone
      }
      birthdate
      livingSituation {
        type
        monthlyPayment
      }
      employment {
        employmentType
        companyName
        roleName
        isWorkingStudent
        otherDescription
      }
      income {
        monthlyIncome
        income3Months
      }
      leadStatus
      createdAt
      updatedAt
    }
  }
`;
