import {
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  all,
  race,
  select,
} from "redux-saga/effects";
import {
  signIn,
  checkLogin,
  signOut,
  listOrganizations,
  completeNewPassword,
} from "api";
import { setError } from "../actions/dialog";
import {
  WORKER_CHECK_LOGIN,
  WORKER_RESET_PASSWORD,
  SIGN_IN,
  SIGN_OUT,
  setLoginChecked,
  setLoggedIn,
  checkLoginWorker,
  setAuthorizationLevel,
  postSignIn,
  setUserData,
} from "../actions/auth";

import { fetchOrganization } from "../actions/organization";
import { fetchLeads } from "../actions/leads";
import {
  EMAIL,
  FACEBOOK,
  GOOGLE,
  UNKNOWN_SIGNIN_PROVIDER,
  AUTH_LEVELS,
} from "../const/auth";

import { isAdminPayload } from "../selectors/auth";
import history from "../../routes/history";

const LOGIN_DEBOUNCE_TIMEOUT = 500;

function* emailSignIn(action) {
  try {
    const user = yield call(signIn, action.email, action.password);
    yield put(setUserData(user));
    localStorage.setItem("tok", user.signInUserSession?.accessToken?.jwtToken);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      yield history.push("/auth/reset-password");
    }
  } catch (err) {
    console.log(err);

    yield put(setError(err.name));
  }
}

function* signOutSaga(action) {
  try {
    const user = yield call(signOut);
    yield put(checkLoginWorker());
  } catch (err) {
    yield put(setError(err.name));
  }
}

function* resetPasswordSaga(action) {
  try {
    const state = yield select();
    yield call(completeNewPassword, state.auth.userData, action.password, {
      given_name: action.name,
      family_name: action.lastName,
      name: `${action.name} ${action.lastName}`,
    });
    yield put(checkLoginWorker());
  } catch (err) {
    console.log(err);
    yield put(setError(err.name));
  }
}

function* signInSaga(action) {
  console.log(action);
  yield delay(LOGIN_DEBOUNCE_TIMEOUT);
  switch (action.provider) {
    case EMAIL:
      yield call(emailSignIn, action);
      yield put(checkLoginWorker());
      break;
    default:
      throw new Error(UNKNOWN_SIGNIN_PROVIDER);
  }
}

function* loginCheckWorker(action) {
  try {
    const user = yield call(checkLogin);
    yield put(setLoggedIn(true));
    // Get Auth level from cognito user
    const authLevel = isAdminPayload(user);
    // Set App level
    yield put(setAuthorizationLevel(authLevel));
    yield put(fetchOrganization());
    yield put(fetchLeads());
    yield put(postSignIn());
  } catch (err) {
    yield put(setError(err.name));
    yield put(setLoggedIn(false));
    console.log(err);
  }
  yield put(setLoginChecked(true));
}

function* loginWatcher() {
  let task;
  while (true) {
    const raceResult = yield race({
      signIn: take(SIGN_IN),
      resetPassword: take(WORKER_RESET_PASSWORD),
      signOut: take(SIGN_OUT),
    });
    if (task != null || task != undefined) {
      yield cancel(task);
    }

    if (raceResult.signIn != null) {
      task = yield fork(signInSaga, raceResult.signIn);
    } else if (raceResult.signOut != null) {
      task = yield fork(signOutSaga, raceResult.signOut);
    } else if (raceResult.resetPassword != null) {
      task = yield fork(resetPasswordSaga, raceResult.resetPassword);
    }
  }
}

function* loginCheckWatcher() {
  while (true) {
    const action = yield take(WORKER_CHECK_LOGIN);
    yield call(loginCheckWorker, action);
  }
}

export default function* authSagas() {
  yield all([loginCheckWatcher(), loginWatcher()]);
}
