import { connect } from "react-redux";
import View from "./view";
import { signOut } from "../../redux/actions/auth";

function mapStateToProps(state, ownProps) {
  return { profile: state.profile };
}

function mapDispatchToProps(dispatch) {
  return { signOut: () => dispatch(signOut()) };
}

const ConnectedView = connect(mapStateToProps, mapDispatchToProps)(View);

export default ConnectedView;
