export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const SAVE_ORGANIZATION_LEAD = "SAVE_ORGANIZATION_LEAD";

export const createOrganization = (organization) => {
  return { type: CREATE_ORGANIZATION, organization };
};

export const updateOrganization = (organization) => {
  return { type: UPDATE_ORGANIZATION, organization };
};

export const fetchOrganization = () => {
  return { type: FETCH_ORGANIZATION };
};

export const setOrganization = (organization) => {
  return { type: SET_ORGANIZATION, organization };
};

export const saveorganizationLead = (lead) => {
  return { type: SAVE_ORGANIZATION_LEAD, lead };
};
